<template>
	<div class="advertisement_list" >
		<div class=""  >
			<div style="display: flex;" v-if="!popList[tabIndex].content" @click="openUrl(popList[tabIndex])">
				<img src="" class="_common-tabs-content"  v-lazy="popList[tabIndex].image" alt="">
			</div>
			<!-- tabIndex -->
			<div v-else class="_common-tabs-content" style="overflow: scroll;" >
				<div class="title">{{popList[tabIndex].title}}</div>
				<div v-html="popList[tabIndex].content"></div>
			</div>
			<div style="padding: 0.3rem;display: flex;overflow-x: scroll;align-items: center;" >
				<div class="consliTst"  :class="{
				'common-tab-item-active':tabIndex==index
			}"  v-for="(item,index) in popList" @click="tabIndex=index" >
					 <svg-icon className="imageWrap"  icon-class="icon_message_pmd--svgSprite:all"  />
					 <span>{{item.title}}</span>
				</div>
			</div>
		</div>
	
	</div>
</template>

<script>
	export default {
		props:{
			popList:{
				type:[Object,Array],
				required:[],
			},
		},
		data() {
			return {
				codeValue: '',
				inputFocus: false,
				tabIndex:0,
			};
		},
		methods: {
			openUrl(e){
				if(e.jump_url && e.jump_url!='#'){
					if(/^http/.test(e.jump_url)){
						window.open(e.jump_url, '_blank');
						return false;
					}
					this.$router.push({name:e.jump_url})
				}
				// if(e.jump_url && e.jump_url!='#'){
				// 	this.$router.push({name:e.jump_url})
				// }
			}
		},
	}
</script>
<style>
	.login-body{
		font-weight: 400;
	}
	.van-popup.advertisement_-conter{
		background-color: transparent; 
	}
	
</style>
<style scoped>
	.common-tab-item-active {
	    background-color: rgba(var(--theme-primay-color-hex),.05)
	}
	.advertisement_list{
		    width: 6.9rem;
			background-color: var(--theme-main-bg-color);
			border-radius: .2rem;
	}
	.advertisement_list .title{
		    color: var(--theme-text-color-darken);
			text-align: center;
			line-height: 1rem;
			font-size: .4rem;
	}
	._common-tabs-content{
		max-height: 5.4rem;
		width: 100%;
	}
	.consliTst{
		display: flex;
		align-items: center;
		border-radius: .1rem;
		margin-right: .1rem;
		height: .8rem !important;
		/* background-color: rgba(var(--theme-primay-color-hex), .05); */
		max-width: 2.05rem;
		padding: .15rem;
		text-align: revert !important;
	}
	.consliTst svg{
		width: .38rem;
		height: .38rem;
		color: var(--theme-primary-color);
		    margin-right: .08rem;
	}
	.consliTst span{
		    font-size: .22rem;
			display: inline-block;
		    line-height: .28rem;
			flex: 1;
			    color: var(--theme-primary-color);
		    overflow: hidden;
		    text-overflow: ellipsis;
		    /* white-space: nowrap; */
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			display: -webkit-box;
				white-space: break-spaces !important;
	}
</style>